<template>
  <div>
    <b-card body>
      <div class="d-flex justify-between">
        <div class="d-flex align-items-start">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1 rounded-lg p-0"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="2x"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <div class="text-black font-semibold text-2xl">
            Data Inbound
          </div>
        </div>
        <div class="mb-2">
          <div class="flex gap-3 justify-end">
            <b-btn
              v-if="data.status === 'Proses'"
              size="md"
              variant="primary"
              :disabled="loading"
              @click="checkConfirm(data.is_retur)"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-[3px]"
              />
              Konfirmasi Inbound
            </b-btn>
            <b-btn
              size="md"
              variant="outline-primary"
              @click="chatButton(data.number_phone)"
            >
              <template>
                <div class="d-flex space-x-3 items-center">
                  <img
                    class="w-6"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/Whatsapp.svg"
                  >
                  <span>Chat Partner</span>
                </div>
              </template>
            </b-btn>
          </div>
          <div
            v-if="data.status === 'Proses'"
            class="mt-1 text-danger font-bold text-sm"
            style="width: 355px"
          >
            *Konfirmasi Inbound setelah barang sampai gudang dan dipastikan
            sesuai dengan data inbound
          </div>
        </div>
      </div>
      <div class="p-2 border-2 rounded-2xl text-black">
        <div class="text-lg font-bold">
          Informasi Inbound
        </div>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Partner
            </div>
          </b-col>
          <b-col>
            <div class="d-flex">
              <b-avatar
                style="width: 40px; height: 40px;"
                rounded="circle"
                :src="data.image_logo_url"
                alt=""
              />
              <span class="text-start ml-1">
                <div class="font-bold">{{ data.partner_name }}</div>
                <div>{{ data.city_name }}</div>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Dikirim dari
            </div>
          </b-col>
          <b-col>
            <div class="capitalize">
              {{ data.send_from }}
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Dikirim ke
            </div>
          </b-col>
          <b-col>
            <div class="capitalize">
              {{ data.send_to }}
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Jenis Pengiriman
            </div>
          </b-col>
          <b-col>
            <div
              v-if="data.is_retur === 0"
              class="capitalize"
            >
              {{
                data.shipping
                  ? data.shipping
                  : data.shipping_type.replace('lainnya-', '')
              }}
            </div>
            <div v-else>
              Retur
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Tanggal Pengiriman
            </div>
          </b-col>
          <b-col>
            <div>
              {{ data.inbound_date ? DAY_MONTH_YEAR(data.inbound_date) : '-' }}
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Estimasi Sampai Gudang
            </div>
          </b-col>
          <b-col>
            <div>
              {{
                data.estimate_date ? DAY_MONTH_YEAR(data.estimate_date) : '-'
              }}
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2 ">
          <b-col lg="3">
            <div class="font-bold">
              Status
            </div>
          </b-col>
          <b-col>
            <div
              v-if="data.status === 'Proses'"
              style="color: #FBA63C"
            >
              Proses
            </div>
            <div
              v-if="data.status === 'Selesai'"
              style="color: #34A770"
            >
              Selesai
            </div>
            <div
              v-if="data.status === 'Dibatalkan'"
              style="color: #E31A1A"
            >
              Dibatalkan
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="flex mt-2 gap-5">
        <div
          class="border-2 rounded-2xl text-black"
          :style="
            history_shipping.length === 0 ? 'width: 100%;' : 'width: 55%;'
          "
        >
          <div class="p-2 text-lg font-bold">
            Informasi Produk
          </div>
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <b-table
              id="table"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              class="text-center detail-card"
              :fields="fieldProduct"
              :items="products"
              responsive
            >
              <template #cell(product_name)="row">
                <div class="d-flex gap-2 items-start text-black">
                  <b-img
                    :src="
                      row.item.image_path
                        ? row.item.image_path
                        : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'
                    "
                    fluid
                    class="image-product"
                  />
                  <div class="">
                    {{ row.item.product_name }}
                    <div class="text-primary text-[12px]">
                      SKU: {{ row.item.sku }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(variant)="row">
                <div
                  v-if="row.item.is_variant === 0"
                  class=""
                >
                  -
                </div>
                <div
                  v-else
                  class="space-y-3"
                >
                  <div
                    v-for="variant in row.item.variant"
                    :key="variant.variant_id"
                  >
                    {{ variant.variant_name ? variant.variant_name : 'null' }}
                  </div>
                </div>
              </template>
              <template #cell(total_inbound)="row">
                <div
                  v-if="row.item.is_variant === 0"
                  class=""
                >
                  {{ row.item.total_inbound }}
                </div>
                <div
                  v-else
                  class="space-y-3"
                >
                  <div
                    v-for="variant in row.item.variant"
                    :key="variant.variant_id"
                  >
                    {{ variant.total_inbound }}
                  </div>
                </div>
              </template>
              <template #cell(total_inbound_confirm)="row">
                <div
                  v-if="row.item.is_variant === 0"
                  class=""
                >
                  {{
                    row.item.total_inbound_confirm === 0 &&
                      data.status === 'Proses'
                      ? '-'
                      : row.item.total_inbound_confirm
                  }}
                </div>
                <div
                  v-else
                  class="space-y-3"
                >
                  <div
                    v-for="variant in row.item.variant"
                    :key="variant.variant_id"
                  >
                    {{
                      variant.total_inbound_confirm === 0 &&
                        data.status === 'Proses'
                        ? '-'
                        : variant.total_inbound_confirm
                    }}
                  </div>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </div>
        <div
          v-if="history_shipping.length !== 0"
          class="border-2 rounded-2xl text-black"
          :style="history_shipping.length === 0 ? 'width: 0%;' : 'width: 45%;'"
        >
          <div class="p-2 text-lg font-bold">
            Status Pengiriman Ekspedisi
          </div>
          <div
            v-if="
              history_shipping.status.code === 200 &&
                history_shipping.manifest !== null
            "
            class="space-y-7 px-2 detail-card"
          >
            <div
              v-for="(item,
                      index) in history_shipping.manifest.slice().reverse()"
              :key="index"
              class="flex"
            >
              <div class="flex flex-column w-25 justify-content-center">
                <span>{{ formatDateManifest(item.manifest_date) }}</span>
                <span>{{ formatTimeManifest(item.manifest_time) }} WIB</span>
              </div>
              <div class="w-25 flex align-items-center justify-content-center">
                <div class="relative">
                  <img
                    :src="ellipseColor(item.manifest_code)"
                    alt=""
                  >
                  <div
                    v-if="
                      item.manifest_code !== `PICKREQ` ||
                        item.manifest_code !== `1`
                    "
                    class="dash border"
                  />
                </div>
              </div>
              <div class="w-75 flex flex-column justify-content-center">
                <span
                  :class="
                    item.manifest_code === `DELIVERED` ||
                      item.manifest_code === `5`
                      ? `text-primary`
                      : ``
                  "
                >{{ item.city_name }}</span>
                <span
                  :class="
                    item.manifest_code === `DELIVERED` ||
                      item.manifest_code === `5`
                      ? `text-primary`
                      : ``
                  "
                >{{ item.manifest_description }}</span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-center flex-column space-y-3"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/tidak-dapat-tracking.svg"
            >
            <div class="text-black font-black text-xl text-center mt-3">
              Ekspedisi yang kamu gunakan belum dapat ditracking melalui Kompack
            </div>
            <div class="text-center mx-3">
              kamu dapat melakukan pengecekan riwayat perjalanan melalui website
              ekspedisi yang kamu gunakan.
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Perhitungan -->
      <b-modal
        id="modal-perhitungan"
        size="lg"
        scrollable
        centered
        hide-header
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        ok-title="Konfirmasi Inbound"
        cancel-title="Batalkan"
        cancel-variant="outline-primary"
        @ok="cekSelisih()"
        @cancel="fetchDetail()"
      >
        <template>
          <div class="text-black text-[20px] font-[600] mb-[24px]">
            Perhitungan Barang
          </div>
          <b-table
            :fields="fieldProduct"
            :items="products"
            sticky-header="300px"
          >
            <template #cell(product_name)="row">
              <div class="text-black">
                <div class="">
                  {{ row.item.product_name }}
                  <div class="text-primary text-[12px]">
                    SKU: {{ row.item.sku }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(variant)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                -
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variant"
                  :key="variant.variant_id"
                >
                  {{ variant.variant_name ? variant.variant_name : 'null' }}
                </div>
              </div>
            </template>
            <template #cell(total_inbound)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                {{ row.item.total_inbound }}
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variant"
                  :key="variant.variant_id"
                >
                  {{ variant.total_inbound }}
                </div>
              </div>
            </template>
            <template #head(total_inbound_confirm)>
              <div class="text-center">
                Jumlah Diterima
              </div>
            </template>
            <template #cell(total_inbound_confirm)="row">
              <div
                v-if="row.item.is_variant === 1"
                class="space-y-2"
              >
                <div
                  v-for="item in row.item.variant"
                  :key="item.variant_id"
                >
                  <div class="d-flex justify-center">
                    <b-button
                      :variant="
                        item.total_inbound_confirm <= 0
                          ? 'outline-secondary'
                          : 'outline-primary'
                      "
                      class="p-0"
                      style="border-radius:50%;width:25px;height:25px"
                      size="sm"
                      :disabled="item.total_inbound_confirm <= 0"
                      @click="item.total_inbound_confirm -= 1"
                    >
                      <span class="font-bold text-lg">-</span>
                    </b-button>
                    <b-input
                      v-model="item.total_inbound_confirm"
                      :formatter="numberOnly"
                      class="total"
                      min="0"
                      number
                    />
                    <b-button
                      variant="outline-primary"
                      class="p-0"
                      style="border-radius:50%;width:25px;height:25px"
                      size="sm"
                      @click="item.total_inbound_confirm++"
                    >
                      <span class="font-bold text-lg">+</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="d-flex justify-center">
                  <b-button
                    :variant="
                      row.item.total_inbound_confirm <= 0
                        ? 'outline-secondary'
                        : 'outline-primary'
                    "
                    class="p-0"
                    style="border-radius:50%;width:25px;height:25px"
                    size="sm"
                    :disabled="row.item.total_inbound_confirm <= 0"
                    @click="row.item.total_inbound_confirm -= 1"
                  >
                    <span class="font-bold text-lg">-</span>
                  </b-button>
                  <b-input
                    v-model="row.item.total_inbound_confirm"
                    :formatter="numberOnly"
                    class="total"
                    min="0"
                    number
                  />
                  <b-button
                    variant="outline-primary"
                    class="p-0"
                    style="border-radius:50%;width:25px;height:25px"
                    size="sm"
                    @click="row.item.total_inbound_confirm++"
                  >
                    <span class="font-bold text-lg">+</span>
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </template>
      </b-modal>
      <!-- Modal Selisih -->
      <b-modal
        id="modal-selisih"
        size="lg"
        scrollable
        centered
        hide-header
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        ok-title="Terima Inbound & Konfirmasi Data"
        cancel-title="Batalkan"
        cancel-variant="outline-primary"
        :ok-disabled="!memo || error !== null"
        :ok-variant="!memo || error !== null ? 'secondary' : 'primary'"
        @ok="inboundConfirmationAlert()"
        @cancel="fetchDetail()"
      >
        <template>
          <div class="text-black text-[20px] font-[600] mb-[24px]">
            Perbedaan Data
          </div>
          <b-alert
            show
            variant="danger"
          >
            <div class="d-flex py-[12px] px-[26px]">
              <img
                class="mr-[12px] w-[38px] h-[38px]"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/info-circle.svg"
                alt="info"
              >
              <div class="text-[#222222]">
                Terdapat perbedaan data antara jumlah yang dikirimkan Partner
                dan yang diterima Mitra Gudang. Harap pastikan perhitungan
                benar.
              </div>
            </div>
          </b-alert>
          <b-table
            :fields="fieldSelisih"
            :items="productSelisih"
            sticky-header="200px"
          >
            <template #cell(product_name)="row">
              <div class="text-black">
                <div class="">
                  {{ row.item.product_name }}
                  <div class="text-primary text-[12px]">
                    SKU: {{ row.item.sku }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(variant)="row">
              <div
                style="min-width: 120px;"
                class="space-y-3"
              >
                <div
                  v-if="row.item.is_variant === 0"
                  class=""
                >
                  -
                </div>
                <div
                  v-else
                  class="space-y-3"
                >
                  <div
                    v-for="variant in row.item.variant"
                    :key="variant.variant_id"
                  >
                    {{ variant.variant_name ? variant.variant_name : 'null' }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(total_inbound)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                {{ row.item.total_inbound }}
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variant"
                  :key="variant.variant_id"
                >
                  {{ variant.total_inbound }}
                </div>
              </div>
            </template>
            <template #head(total_inbound_confirm)>
              <div class="">
                Jumlah Diterima
              </div>
            </template>
            <template #cell(total_inbound_confirm)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                {{ row.item.total_inbound_confirm }}
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variant"
                  :key="variant.variant_id"
                >
                  {{ variant.total_inbound_confirm }}
                </div>
              </div>
            </template>
            <template #cell(selisih)="row">
              <div
                class="space-y-3"
                style="min-width: 80px;"
              >
                <div v-if="row.item.is_variant === 0">
                  {{ row.item.selisih }}
                </div>
                <div
                  v-else
                  class="space-y-3"
                >
                  <div
                    v-for="variant in row.item.variant"
                    :key="variant.variant_id"
                  >
                    {{ variant.selisih }}
                  </div>
                </div>
              </div>
            </template>
          </b-table>
          <div class="text-[#222222] mt-[24px]">
            Jika data sudah benar, tuliskan memo kepada Partner dibawah ini
            untuk konfirmasi perbedaan data
            <span class="text-danger">(Memo wajib diisi)</span>
          </div>
          <b-form-textarea
            v-model="memo"
            placeholder="Tuliskan memo/link video unboxing ..."
            class="mt-[24px]"
            :formatter="formatTextArea"
          />
          <div class="text-danger text-[12px] mt-[8px]">
            {{ error }}
          </div>
        </template>
      </b-modal>
      <!-- Modal Konfirmasi Retur -->
      <b-modal
        id="modal-konfirmasi-retur"
        size="sm"
        centered
        hide-header
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        ok-title="Konfirmasi"
        cancel-title="Kembali"
        cancel-variant="outline-primary"
        :ok-variant="isRusak !== null ? 'primary' : 'secondary'"
        :ok-disabled="isRusak === null"
        @cancel="isRusak = null"
        @ok="confirmRetur()"
      >
        <template>
          <div class="text-black text-center text-[20px] font-[600] mb-[24px]">
            Konfirmasi Inbound Retur
          </div>
          <div class="text-[#222222] font-[600] mb-[12px]">
            Kondisi barang :
          </div>
          <b-form-radio-group
            v-model="isRusak"
            :options="kondisiOptions"
            value-field="value"
            text-field="name"
            class="text-[#222222] space-y-3"
          />
        </template>
      </b-modal>
      <!-- Modal Ajukan Barang Rusak -->
      <b-modal
        id="modal-ajukan-barang-rusak"
        size="lg"
        scrollable
        centered
        hide-header
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        ok-title="Konfirmasi Inbound"
        cancel-title="Batalkan"
        cancel-variant="outline-primary"
        @ok="cekBarangRusak"
        @cancel="fetchDetail()"
      >
        <template>
          <div class="text-black text-[20px] font-[600] mb-[24px]">
            Ajukan Barang Rusak
          </div>
          <b-table
            :fields="fieldBarangRusak"
            :items="products"
            sticky-header="300px"
          >
            <template #cell(product_name)="row">
              <div class="text-black">
                <div class="">
                  {{ row.item.product_name }}
                  <div class="text-primary text-[12px]">
                    SKU: {{ row.item.sku }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(variant)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                -
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variant"
                  :key="variant.variant_id"
                >
                  {{ variant.variant_name ? variant.variant_name : 'null' }}
                </div>
              </div>
            </template>
            <template #cell(total_inbound)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                {{ row.item.total_inbound }}
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variant"
                  :key="variant.variant_id"
                >
                  {{ variant.total_inbound }}
                </div>
              </div>
            </template>
            <template #head(total_inbound_confirm)>
              <div class="text-center">
                Barang Rusak
              </div>
            </template>
            <template #cell(total_inbound_confirm)="row">
              <div
                v-if="row.item.is_variant === 1"
                class="space-y-2"
              >
                <div
                  v-for="item in row.item.variant"
                  :key="item.variant_id"
                >
                  <div class="d-flex justify-center">
                    <b-button
                      :variant="
                        item.total_inbound_confirm <= 0
                          ? 'outline-secondary'
                          : 'outline-primary'
                      "
                      class="p-0"
                      style="border-radius:50%;width:25px;height:25px"
                      size="sm"
                      :disabled="item.total_inbound_confirm <= 0"
                      @click="item.total_inbound_confirm -= 1"
                    >
                      <span class="font-bold text-lg">-</span>
                    </b-button>
                    <div class="total">
                      {{ item.total_inbound_confirm }}
                    </div>
                    <b-button
                      variant="outline-primary"
                      class="p-0"
                      style="border-radius:50%;width:25px;height:25px"
                      size="sm"
                      :disabled="
                        item.total_inbound_confirm >= item.total_inbound
                      "
                      @click="item.total_inbound_confirm++"
                    >
                      <span class="font-bold text-lg">+</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="d-flex justify-center">
                  <b-button
                    :variant="
                      row.item.total_inbound_confirm <= 0
                        ? 'outline-secondary'
                        : 'outline-primary'
                    "
                    class="p-0"
                    style="border-radius:50%;width:25px;height:25px"
                    size="sm"
                    :disabled="row.item.total_inbound_confirm <= 0"
                    @click="row.item.total_inbound_confirm -= 1"
                  >
                    <span class="font-bold text-lg">-</span>
                  </b-button>
                  <div class="total">
                    {{ row.item.total_inbound_confirm }}
                  </div>
                  <b-button
                    variant="outline-primary"
                    class="p-0"
                    style="border-radius:50%;width:25px;height:25px"
                    size="sm"
                    :disabled="
                      row.item.total_inbound_confirm >= row.item.total_inbound
                    "
                    @click="row.item.total_inbound_confirm++"
                  >
                    <span class="font-bold text-lg">+</span>
                  </b-button>
                </div>
              </div>
            </template>
          </b-table>
        </template>
      </b-modal>
      <!-- Modal Barang Rusak -->
      <b-modal
        id="modal-barang-rusak"
        size="lg"
        scrollable
        centered
        hide-header
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        ok-title="Konfirmasi Inbound & Kerusakan Barang"
        cancel-title="Batalkan"
        cancel-variant="outline-primary"
        :ok-disabled="!memo || error !== null"
        :ok-variant="!memo || error !== null ? 'secondary' : 'primary'"
        @ok="confirmInbound(data.id)"
        @cancel="fetchDetail()"
      >
        <template>
          <div class="text-black text-[20px] font-[600] mb-[24px]">
            Ajukan Barang Rusak
          </div>
          <b-table
            :fields="fieldBarangRusak"
            :items="productRusak"
            sticky-header="200px"
          >
            <template #cell(product_name)="row">
              <div class="text-black">
                <div class="">
                  {{ row.item.product_name }}
                  <div class="text-primary text-[12px]">
                    SKU: {{ row.item.sku }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(variant)="row">
              <div
                style="min-width: 120px;"
                class="space-y-3"
              >
                <div
                  v-if="row.item.is_variant === 0"
                  class=""
                >
                  -
                </div>
                <div
                  v-else
                  class="space-y-3"
                >
                  <div
                    v-for="variant in row.item.variants"
                    :key="variant.option_id"
                  >
                    {{ variant.variant_name ? variant.variant_name : 'null' }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(total_inbound)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                {{ row.item.total_inbound }}
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variants"
                  :key="variant.option_id"
                >
                  {{ variant.total_inbound }}
                </div>
              </div>
            </template>
            <template #head(total_inbound_confirm)>
              <div class="">
                Barang Rusak
              </div>
            </template>
            <template #cell(total_inbound_confirm)="row">
              <div
                v-if="row.item.is_variant === 0"
                class=""
              >
                {{ row.item.total }}
              </div>
              <div
                v-else
                class="space-y-3"
              >
                <div
                  v-for="variant in row.item.variants"
                  :key="variant.option_id"
                >
                  {{ variant.total }}
                </div>
              </div>
            </template>
          </b-table>
          <div class="text-[#222222] mt-[24px]">
            Jika data sudah benar, tuliskan memo kepada Partner dibawah ini
            untuk konfirmasi data barang rusak
            <span class="text-danger">(Memo wajib diisi)</span>
          </div>
          <b-form-textarea
            v-model="memo"
            placeholder="Tuliskan memo/link video unboxing ..."
            class="mt-[24px]"
            :formatter="formatTextArea"
          />
          <div class="text-danger text-[12px] mt-[8px]">
            {{ error }}
          </div>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { INBOUND, OUTBOUND } from '@/provider/url'
import secureLocalStorage from '@/libs/secureLocalstorage'
import moment from 'moment'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import chatButton from '@/libs/chatButton'
import { numberOnly } from '@/libs/helpers'
import {
  fieldProduct,
  fieldSelisih,
  kondisiOptions,
  fieldBarangRusak,
} from './config'

export default {
  data() {
    return {
      chatButton,
      loading: false,
      loadingConfirm: false,
      data: JSON.parse(secureLocalStorage.get('dataInbound')),
      products: [],
      productSelisih: [],
      productRusak: [],
      productByInbound: [],
      history_shipping: [],
      memo: '',
      error: 'Minimal 10 karakter',
      isRusak: null,
      DAY_MONTH_YEAR,
      numberOnly,

      fieldProduct,
      fieldSelisih,
      kondisiOptions,
      fieldBarangRusak,
    }
  },

  created() {
    this.fetchDetail()
    this.fetchProduct()
  },

  methods: {
    async fetchDetail() {
      this.loading = true
      await provider
        .single(`${INBOUND}/${this.data.id}/detail`)
        .then(response => {
          const { products } = response.data
          const { history_shipping } = response.data

          this.products = products
          // eslint-disable-next-line camelcase
          this.history_shipping = history_shipping

          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async fetchProduct() {
      // this.loading = true
      await provider
        .single(
          `/v1/kompack/outbound/non-pickup/product/${this.data.partner_id}/inbound/${this.data.id}`,
        )
        .then(response => {
          const { data } = response

          this.productByInbound = data
          // this.loading = false
        })
        .catch(error => {
          // this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    // eslint-disable-next-line camelcase
    confirmInbound(inbound_id) {
      const transformedData = this.products.map(
        ({
          id, is_variant, total_inbound, total_inbound_confirm, variant,
        }) => {
          const transformedObj = {
            product_id: id,
            total_inbound_confirm:
              // eslint-disable-next-line camelcase
              this.data.is_retur === 1 ? total_inbound : total_inbound_confirm,
            variant,
          }

          // eslint-disable-next-line camelcase
          if (is_variant === 1) {
            transformedObj.variant = variant.map(
              ({
                // eslint-disable-next-line camelcase
                variant_id: option_id,
                // eslint-disable-next-line no-shadow
                total_inbound,
                // eslint-disable-next-line no-shadow
                total_inbound_confirm,
              }) => ({
                option_id,
                total_inbound_confirm:
                  // eslint-disable-next-line camelcase
                  this.data.is_retur === 1 ? total_inbound : total_inbound_confirm,
              }),
            )
          }

          return transformedObj
        },
      )

      this.productRusak.forEach(product => {
        if (product.is_variant) {
          product.variants.forEach(variant => {
            const match = this.productByInbound.find(p => p.id === product.product_id)
              .variant.find(v => v.option_id === variant.option_id)
            if (match) {
              // eslint-disable-next-line no-param-reassign
              variant.warehouse_variant_id = match.variant_id
            }
          })
        }
      })

      const payload = {
        inbound_id,
        memo: this.isRusak === 1 ? '' : this.memo,
        product: transformedData,
      }

      const payloadOutbound = {
        partner_id: this.data.partner_id,
        memo: this.memo,
        products: this.productRusak,
      }

      provider
        .update(`${INBOUND}/confirmation`, payload)
        .then(response => {
          if (response.code === 200) {
            this.$swal({
              title: 'Konfirmasi Inbound Berhasil',
              text: 'Yeay... Inbound berhasil terkonfirmasi. Klik selesai untuk melihat dtail data inbound kamu',
              icon: 'success',
              width: '37%',
              iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png">',
              confirmButtonText: 'Selesai',
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                icon: 'border-0 w-50 my-5',
                confirmButton: 'btn btn-primary px-4',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                this.fetchDetail()
                this.data.status = 'Selesai'
                secureLocalStorage.set('dataInbound', JSON.stringify(this.data))
              }
            })
            if (this.isRusak === 1) {
              provider
                .insert({
                  url: `${OUTBOUND}/non-pickup/store`,
                  body: payloadOutbound,
                })
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    confirmRetur() {
      if (this.isRusak === 0) {
        this.confirmInbound(this.data.id)
      } else {
        this.$bvModal.show('modal-ajukan-barang-rusak')
      }
    },
    cekSelisih() {
      const filteredData = this.products.filter(item => {
        if (item.is_variant === 1) {
          return item.variant.some(
            v => v.total_inbound !== v.total_inbound_confirm,
          )
        }
        return item.total_inbound !== item.total_inbound_confirm
      })

      const transformedData = filteredData.map(item => {
        if (item.is_variant === 1) {
          const newVariant = item.variant
            .filter(v => v.total_inbound !== v.total_inbound_confirm)
            .map(v => ({
              ...v,
              selisih:
                v.total_inbound_confirm > v.total_inbound
                  ? `Lebih ${v.total_inbound_confirm - v.total_inbound}`
                  : `Kurang ${v.total_inbound - v.total_inbound_confirm}`,
            }))
          return { ...item, variant: newVariant }
        }
        return {
          ...item,
          selisih:
            item.total_inbound_confirm > item.total_inbound
              ? `Lebih ${item.total_inbound_confirm - item.total_inbound}`
              : `Kurang ${item.total_inbound - item.total_inbound_confirm}`,
        }
      })

      this.productSelisih = transformedData

      if (this.productSelisih.length > 0) {
        this.$bvModal.show('modal-selisih')
      } else {
        this.isRusak = 0
        this.inboundConfirmationAlert()
      }
    },
    cekBarangRusak() {
      const filteredData = this.products.filter(item => {
        if (item.is_variant === 1) {
          return item.variant.some(v => v.total_inbound_confirm !== 0)
        }
        return item.total_inbound_confirm !== 0
      })

      const transformedData = filteredData.map(item => {
        if (item.is_variant === 1) {
          const newVariant = item.variant.filter(
            v => v.total_inbound_confirm !== 0,
          )
          return { ...item, variant: newVariant }
        }
        return { ...item }
      })

      const toOptionId = transformedData.map(item => {
        if (item.is_variant) {
          const newVariant = item.variant.map(option => ({
            option_id: option.variant_id,
            warehouse_variant_id: null,
            variant_name: option.variant_name,
            total_inbound: option.total_inbound,
            total: option.total_inbound_confirm,
          }))
          return {
            product_id: item.id,
            product_name: item.product_name,
            image_path: item.image_path,
            sku: item.sku,
            is_variant: item.is_variant,
            total_inbound: item.total_inbound,
            total: item.total_inbound_confirm,
            variants: newVariant,
          }
        }
        return {
          product_id: item.id,
          product_name: item.product_name,
          image_path: item.image_path,
          sku: item.sku,
          is_variant: item.is_variant,
          total_inbound: item.total_inbound,
          total: item.total_inbound_confirm,
          variants: [],
        }
      })

      this.productRusak = toOptionId

      if (this.productRusak.length > 0) {
        this.$bvModal.show('modal-barang-rusak')
      } else {
        this.isRusak = 0
        this.confirmInbound(this.data.id)
      }
    },
    inboundConfirmationAlert() {
      this.$swal({
        title: 'Konfirmasi Inbound',
        text:
          'Kamu yakin barang inbound sudah sesuai? Status inbound bakal berubah menjadi selesai lho...',
        icon: 'warning',
        width: '37%',
        reverseButtons: true,
        iconHtml:
          '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Konfirmasi',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          icon: 'border-0 w-50 my-5',
          confirmButton: 'btn btn-primary px-4',
          cancelButton: 'btn btn-outline-primary mr-1 px-5',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.confirmInbound(this.data.id)
        }
      })
    },
    formatDateManifest(value) {
      return moment(value).format('DD MMM')
    },
    formatTimeManifest(value) {
      return value.replace(/:[^:]*$/, '')
    },
    ellipseColor(value) {
      if (value === 'DELIVERED' || value === '5') {
        return 'https://storage.googleapis.com/komerce/assets/tracking-ellipse-orange.svg'
      }
      return 'https://storage.googleapis.com/komerce/assets/tracking-elipse.svg'
    },
    checkConfirm(retur) {
      if (retur === 0) this.$bvModal.show('modal-perhitungan')
      else this.$bvModal.show('modal-konfirmasi-retur')
    },
    formatTextArea(value) {
      if (value.length >= 256) {
        this.error = 'Maksimal 255 karakter'
        return value.slice(0, 256)
      }
      if (value.length < 10) {
        this.error = 'Minimal 10 karakter'
      } else {
        this.error = null
      }
      return value
    },
  },
}
</script>

<style scoped>
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px !important;
  height: 50px !important;
}

.dash {
  position: absolute;
  height: 45px;
  margin: 4px 0px;
  margin-left: 5px;
}

.detail-card {
  height: 400px;
  overflow-y: auto;
}

.total {
  width: 20%;
  border-bottom: solid 2px #e2e2e2 !important;
  border: none;
  border-radius: 0px;
  padding: 0px;
  margin: 0px 10px;
  text-align: center;
}
</style>
